<template>
  <div id="app">
    <router-view :key="$route.fullPath" />

 <audio controls="controls" hidden src="https://dict.youdao.com/dictvoice?audio=%20%20%20%E5%B0%8F%E5%A4%A7%E5%85%B0%E5%85%B0%20%E5%93%88%E5%93%88%E5%93%88%E5%B0%B1%E6%98%AF%E6%9D%80%E6%8E%89&le=zh" ref="audio"></audio>


  </div>
</template>

<script>

import { report } from '@/api'
import { setSkin, getSkin } from '@/utils/auth'

export default {
  name: 'App',
  data() {
    return {
          lockReconnect: false,
          ws: null,
    }
 },
  created() {
    report();
    this.join();
  },
  beforeCreate() {
    if (getSkin() == null) {
      setSkin("shallow")
    } else {
      setSkin(getSkin())
    }
  },
  mounted() {
    this.$setSkin();

  
  },
  methods:{
    join() {
      const Wsurl = `wss://tapi.xuejss.com/wss/`
      this.ws = new WebSocket(Wsurl);
      const self = this;
      this.ws.onopen = function (event) {
        console.log('已经打开连接');
        //心跳检测重置
        self.heartCheckReset()
        self.heartCheckStart()
        self.text_content = self.text_content + "已经打开连接!" + "\n";
        let message = {uid: 999, isws: 'istrue'}
        self.ws.send(JSON.stringify(message));
      };
        this.ws.onmessage = function (event) {
        self.heartCheckReset()
        self.heartCheckStart()
        self.text_content = event.data + "\n";
        // console.log('收到消息'+event);
        if (event.data == 'ping' || event.data == '连接成功') { 
          //  console.log(''+event);
        }
      
        if (event.data != 'ping' && event.data!='') { 
          var json = eval('(' + event.data + ')');
           //这里开始写逻辑
           if(json.type=='doplay'){
            //播报


           }
           if(json.types=='login'){
            //播报

            // let audioElement = new Audio('https://dict.youdao.com/dictvoice?audio=%20%20%20%E5%B0%8F%E5%A4%A7%E5%85%B0%E5%85%B0%20%E5%93%88%E5%93%88%E5%93%88%E5%B0%B1%E6%98%AF%E6%9D%80%E6%8E%89&le=zh');
            //  audioElement.play();

           }
         //  let audioElement = new Audio('https://dict.youdao.com/dictvoice?audio=%20%20%20%E5%B0%8F%E5%A4%A7%E5%85%B0%E5%85%B0%20%E5%93%88%E5%93%88%E5%93%88%E5%B0%B1%E6%98%AF%E6%9D%80%E6%8E%89&le=zh');
            // audioElement.play();

            // let iframe = document.createElement('iframe');
 //iframe.src="https://dict.youdao.com/dictvoice?audio=%20%20%20%E5%B0%8F%E5%A4%A7%E5%85%B0%E5%85%B0%20%E5%93%88%E5%93%88%E5%93%88%E5%B0%B1%E6%98%AF%E6%9D%80%E6%8E%89&le=zh";
//document.body.appendChild(iframe);

          console.log(''+json.percent);


        }
  
      };
        this.ws.onclose = function (event) {
        console.log('关闭');
        console.log(event, '关闭');
        self.reconnect()
        self.text_content = self.text_content + "已经关闭连接!" + "\n";
      };
},
 //若链接中断30秒后创建新的链接
    reconnect() {
      if (this.lockReconnect) return ''
      this.lockReconnect = true
      setTimeout(() => {
        this.join()
        this.lockReconnect = false
        //若链接中断30秒后创建新的链接
      }, 10000)
    },
    //清空定时器
	heartCheckReset() {
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
    },
        // 开启定时器
    heartCheckStart() {
      var self = this;
      this.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        //onmessage拿到返回的心跳就说明连接正常
        self.ws.send("ping");
        console.log("ping!")
        self.serverTimeoutObj = setTimeout(function () { //如果超过一定时间还没重置，说明后端主动断开了
          self.ws.close();
        },10000 )
      }, 10000)

    },

  },
}
</script>

<style lang="scss" scoped>
#app {
  background: var(--body-color);
}

/deep/ .el-card__body {
  padding: 0;
  margin: 4px;
}

/deep/ .el-card {
  border: 0;
}


@media screen and (max-width: 1118px) {
  .dialog {
    width: 100%;

    /deep/ .el-dialog {
      width: 100% !important;
    }
  }
}
</style>
